import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { getOneCompany } from '../../api/CompanyApi';
import { editCompanySettings } from '../../api/CompanyApi';
import { getAllUsersByOrganizationId } from '../../api/UserApi';

import { URLS } from '../../utils/urls.util';
import { useApi } from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';

import { getAppSettings } from '../../api/AppSettings';
import { getItvSystems } from '../../api/ItvSystemApi';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { CompanySettingsValidationConfig } from '../../utils/formValidatorConfigs/config';
import { FormValidator } from '../FormValidator/FormValidator';

export const SingleCompanySettings = ({ id, permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const editCompanySettingsApi = useApi(editCompanySettings);
  const getAppSettingsApi = useApi(getAppSettings);
  const getItvSystemsApi = useApi(getItvSystems);
  const userApi = useApi(getAllUsersByOrganizationId);
  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const [wasChange, setChange] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [validateErrors, setValidateErrors] = useState([]);
  const [settingsData, setSettingsData] = useState({});
  const [isItvAllowed, setItvAllowed] = useState(false);
  const [itvSystems, setItvSystems] = useState([]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {company.title}
    </Typography>,
    <Typography key="3" color="text.primary">
      Настройки
    </Typography>,
  ];

  const config = useMemo(() => CompanySettingsValidationConfig(), []);
  const dispatch = useDispatch();

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  useEffect(() => {
    if (id !== 'create') {
      getOneCompanyApi.sendRequest(id, ['meta']).then((company) => {
        setCompany(company);
        setSettingsData({
          Id: company.id,
          IsItvEnabled: company.IsItvEnabled || false,
          MaxCameraCount: company.MaxCameraCount || 1,
          ItvSystemId: company.ItvSystemId || null
        });

        userApi.sendRequest(
          10,
          0,
          {
            q: `organizationId:${company.id}`,
          },
          ['/OrganizationAdministrator'],
        )
        .then(([result, count]) => {
          const employeeCount = result?.filter(
            (e) => e.enabled === true,
          )?.length;
          setItvAllowed(employeeCount > 0);
        });
      });
    }
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
    getItvSystemsApi.sendRequest()
      .then((result) => setItvSystems(result.items))
      .catch((err) => console.error(err));
  }, [id]);

  return (
    <FullPageLayout
      obj={company}
      seoTitle={'Настройки - ' + company?.title}
      pageTitle={company.title}
      isCreated={false}
      breadcrumbs={breadcrumbs}
      showHiddenData={true}
    >
      <SingleCompanyNavigation
        company={company}
        activeTab={6}
        wasChange={wasChange}
        appSettings={appSettings}
      />
      <FormValidator
        name="company-settings-form-name"
        validationConfig={config}
        changed={settingsData}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Typography sx={{ mt: 3 }} variant="h5">
          Настройки
        </Typography>
        {!!Object.keys(settingsData)?.length && (
          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Switch
                checked={settingsData?.IsItvEnabled}
                defaultChecked={settingsData?.IsItvEnabled}
                disabled={!permission.edit || !isItvAllowed}
                onChange={({ target }) => {
                  if (!target.checked) {
                    setOpenApprove(true);
                  } else {
                    setSettingsData({
                      ...settingsData,
                      IsItvEnabled: target.checked,
                    });
                  }
                }}
              />
            }
            label="Использование системы ITV"
          />
        )}
        {!isItvAllowed && (
          <InputLabel>
            Использование системы ITV недоступно, так как в организации нет ни одного администратора.
          </InputLabel>
        )}
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <TextField
            label="Максимальное количество камер"
            sx={{ width: '250px' }}
            type="number"
            name="MaxCameraCount"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.IsItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.MaxCameraCount.isRequired}
            value={settingsData?.MaxCameraCount?.toString() || 0}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, MaxCameraCount: +value || 0 })
            }
          />
          <FormControl
            fullWidth
            sx={{ width: '500px' }}
            required={config.ItvSystemId.isRequired}
          >
            <InputLabel id="itv-system-label">Система ITV</InputLabel>
            <Select
              id="itv-system-select"
              labelId="itv-system-label"
              InputLabelProps={{
                shrink: true,
              }}
              name="ItvSystemId"
              disabled={!permission.edit || !settingsData.IsItvEnabled}
              required={config.ItvSystemId.isRequired}
              value={settingsData?.ItvSystemId?.toString() || ''}
              label="Система ITV"
              onChange={({ target: { value } }) =>
                setSettingsData({ ...settingsData, ItvSystemId: value })
              }
            >
              {itvSystems.map((s) => (
                  <MenuItem value={s.id}>{s.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            sx={{ width: '103px' }}
            disabled={!permission.edit || !settingsData?.IsItvEnabled}
            onClick={() => {
              editCompanySettingsApi.sendRequest(settingsData)
                .then(() =>
                  dispatch(
                    setAlert({
                      text: 'Настройки организации обновлены.',
                      status: 200,
                    }),
                  ),
                )
                .catch((err) => console.error(err));
            }}
          >
            Задать
          </Button>
        </Stack>
      </FormValidator>

      <ActionApproveV2
          open={openApprove}
          title="Вы действительно хотите отключить использование системы ITV для этой организации?"
          handleClose={() => setOpenApprove(false)}
          showDescription={false}
          handleSuccess={() => {
            setSettingsData({
              ...settingsData,
              IsItvEnabled: false
            });
            editCompanySettingsApi.sendRequest({
              ...settingsData,
              IsItvEnabled: false
            })
              .then(() =>
                dispatch(
                  setAlert({
                    text: 'Настройки организации обновлены.',
                    status: 200,
                  }),
                ),
              )
              .catch((err) => console.error(err));
            setOpenApprove(false);
          }}
          actionButtonText="Да"
          handleCloseText="Нет"
        />
    </FullPageLayout>
  );
};
